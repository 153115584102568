import React, {
  memo,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { ApiDispatchContext } from "../../context/ApiContext";
import { useInjectReducer } from "../../utils/injectReducer";
import makeSelectCasesDetails, { makeMultiSelectCases } from "./selectors";
import Case from "../../components/case";
import "./casesDetails.css";
import CommonNavbar from "../../components/CommonNavbar/index";
import reducer from "./reducer";
import { getEmployeeRole } from "../App/selectors";
import { CaseCard } from "./CaseCard";
import { casesUpdate, setMultiCaseId } from "./actions";
import { makeSelectCardCases } from "./selectors";
import { dummyOpenCases } from "../../constants/cardConstants";
import {
  caseCountApiCall,
  getUrlParameter,
} from "../../constants/commonFunction";
import ErrorAlert from "../../components/ErrorAlert";
import ReactPaginate from "../../components/ReactPaginate/index";
import path from "../../constants/pathConstants";
import propTypes from "prop-types";
import { push } from "connected-react-router";
import FilterPopUp from "../../components/FilterPopUp";
import FilterActions from "../../components/FilterActions";
import { handleExportClick } from "../../constants/commonFunction";
import AcceptButtons from "./AcceptButtons";
import { useNavigate } from "react-router-dom";
export function CasesDetails({
  updateCase,
  getCases,
  employeeRole,
  redirectPath,
  setMultiCase,
  caseidArr,
}) {
  const [casesCards, setCasesCards] = useState([]);
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalrecords, settotalrecords] = useState(0);
  const [pageCount, setpageCount] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const toggle = () => setModal(!modal);
  const [filterflag, setfilterflag] = useState(false);
  const [filterform, setfilterform] = useState({});
  const [payloadExcelDownload, setpayloadExcelDownload] = useState({});
  const [showSelect, setShowSelect] = useState(false);
  const multiSelect = Boolean(getUrlParameter("multiSelect"));
  const [filterHeight, setfilterHeight] = useState("auto");

  const navigate = useNavigate();

  useEffect(() => {
    if (multiSelect && caseidArr && caseidArr.length > 0) {
      setShowSelect(true);
    } else {
      setShowSelect(false);
      setMultiCase([]);
    }
    api
      .casesCount()
      .then((result) => {
        if (result.data && result.data.success) {
          const tempCases = caseCountApiCall(
            employeeRole,
            dummyOpenCases,
            result
          );
          setCasesCards(tempCases);
        } else {
          setModalTitle("Error");
          if (result.data.error && result.data.message) {
            setModalBody(result.data.message);
          } else {
            setModalBody("Please try after sometime");
          }
          toggle();
        }
      })
      .catch((err) => {
        setModalTitle("Error");
        if (err.response && err.response.data && err.response.data.message) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody("Please try after sometime");
        }
        toggle();
      });
    let param = {
      caseType: "open",
      page: 1,
      pageSize: 10,
    };
    api
      .getCasesDetails(param)
      .then((data) => {
        if (data.data && data.data.success) {
          updateCase(data.data.data.rows);
          settotalrecords(data.data.data.count);
          const pageSize = Math.ceil(data.data.data.count / 10);
          setpageCount(pageSize);
        } else {
          setModalTitle("Error");
          if (data.data.error && data.data.message) {
            setModalBody(data.data.message);
          } else {
            setModalBody("Please try after sometime");
          }
          toggle();
        }
      })
      .catch((err) => {
        setModalTitle("Error");
        if (err.response && err.response.data && err.response.data.message) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody("Please try after sometime");
        }
        toggle();
      });
  }, []);

  const onCasesClick = (path) => {
    navigate(path);
  };

  const filterpopupShow = () => {
    setOpen(true);
  };

  const filterToggle = () => {
    setOpen(!open);
  };

  const submitFilterform = useCallback(
    (data) => {
      setfilterform(data);
      const payload = {
        caseType: "open",
        filter: {
          customersegmenttype: {},
        },
      };
      data.forEach((item) => {
        if (item.label === "Date") {
          if (item.selectedvalue1) {
            const date = new Date(item.selectedvalue1);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            payload.filter.fromDate = formattedDate;
          } else {
            payload.filter.fromDate = "";
          }
          if (item.selectedvalue2) {
            const date = new Date(item.selectedvalue2);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            payload.filter.toDate = formattedDate;
          } else {
            payload.filter.toDate = "";
          }
        } else {
          const key = item.label.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
          const dataArray = item.value;
          for (let i = 0; i < dataArray.length; i++) {
            if (dataArray[i].value === item.selectedvalue1) {
              payload.filter[key] = {
                id: dataArray[i].id,
                column_name: dataArray[i].column_name,
                value: item.selectedvalue1,
              };
              break; // Stop the loop after the first match
            } else {
              payload.filter[key] = {};
            }
          }
        }
      });
      setpayloadExcelDownload(payload);
      api
        .getCasesDetails(payload)
        .then((data) => {
          if (data.data && data.data.success) {
            setfilterflag(true);
            updateCase(data.data.data.rows);
          } else {
            setModalTitle("Error");
            if (data.data.error && data.data.message) {
              setModalBody(data.data.message);
            } else {
              setModalBody("Please try after sometime");
            }
            toggle();
          }
        })
        .catch((err) => {
          setModalTitle("Error");
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            setModalBody(err.response.data.message);
          } else {
            setModalBody("Please try after sometime.");
          }
          toggle();
        });
      setOpen(false);
    },
    []
  );

  const resetFilter = useCallback(() => {
    setfilterform({});
    let param = {
      caseType: "open",
      page: 1,
      pageSize: 10,
    };
    api
      .getCasesDetails(param)
      .then((data) => {
        if (data.data && data.data.success) {
          setfilterflag(false);
          updateCase(data.data.data.rows);
          settotalrecords(data.data.data.count);
          const pageSize = Math.ceil(data.data.data.count / 10);
          setpageCount(pageSize);
        } else {
          setModalTitle("Error");
          if (data.data.error && data.data.message) {
            setModalBody(data.data.message);
          } else {
            setModalBody("Please try after sometime");
          }
          toggle();
        }
      })
      .catch((err) => {
        setModalTitle("Error");
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody("Please try after sometime.");
        }
        toggle();
      });
  }, []);

  const downloadReport = useCallback(() => {
    let param = {
      caseType: "open",
    };
    if (filterflag) {
      param = payloadExcelDownload;
    }
    api
      .getReportExcel(param)
      .then((data) => {
        if (data.data.success) {
          handleExportClick(data.data.data, "Open_Report", "Open_Report");
        } else {
          setModalTitle("Error");
          if (data.data.error && data.data.message) {
            setModalBody(data.data.message);
          } else {
            setModalBody("Please try after sometime");
          }
          toggle();
        }
      })
      .catch((err) => {
        setModalTitle("Error");
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody("Please try after sometime.");
        }
        toggle();
      });
  }, [filterflag, payloadExcelDownload]);

  useInjectReducer({ key: "casesDetails", reducer });

  const handleMultiCaseSelect = useCallback(
    (caseid) => {
      if (caseidArr.indexOf(caseid) !== -1) {
        let index = caseidArr.indexOf(caseid);
        caseidArr.splice(index, 1);
        setMultiCase([...caseidArr]);
      } else {
        setMultiCase([...caseidArr, caseid]);
      }
    },
    [caseidArr]
  );

  const renderItemssearch = useCallback(() => {
    let tempdata = [];
    for (var i = 0; i < getCases.length; i++) {
      tempdata[i] = getCases[i];
    }
    return (
      <CaseCard
        selectCards={tempdata}
        showSelect={showSelect}
        handleMultiCaseSelect={handleMultiCaseSelect}
      />
    );
  }, [getCases, showSelect, handleMultiCaseSelect]);

  //render items casecard
  const renderItems = useCallback(() => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    let tempdata = [];
    for (var i = 0; i < getCases.length; i++) {
      if (i >= 0 && i < 10) {
        tempdata[i] = getCases[i];
      }
    }
    return (
      <CaseCard
        selectCards={tempdata}
        showSelect={showSelect}
        handleMultiCaseSelect={handleMultiCaseSelect}
      />
    );
  }, [currentPage, itemsPerPage, getCases, showSelect, handleMultiCaseSelect]);

  const redirectAssignCase = () => {
    navigate(
      path.ASSIGN_INVESTIGATOR +
        "?" +
        btoa("case=" + [...caseidArr] + "&openCases=" + true)
    );
  };

  const doNotAcceptCase = () => {
    navigate(
      path.DO_NOT_ACCEPT_CASE +
        "?" +
        btoa("case=" + [...caseidArr] + "&openCases=" + true)
    );
  };

  const handleShowSelect = (show) => {
    setShowSelect(show);
    setMultiCase([]);
  };

  const datepickerClicked = (value) => {
    if (value === false) {
      setfilterHeight("auto");
    } else setfilterHeight("450px");
  };

  //on changes of page update current page
  const handlePageChange = useCallback(({ selected }) => {
    let param = {
      caseType: "open",
      page: selected + 1,
      pageSize: 10,
    };
    api
      .getCasesDetails(param)
      .then((data) => {
        if (data.data && data.data.success) {
          updateCase(data.data.data.rows);
          settotalrecords(data.data.data.count);
          const pageSize = Math.ceil(data.data.data.count / 10);
          setpageCount(pageSize);
          renderItems();
        } else {
          setModalTitle("Error");
          if (data.data.error && data.data.message) {
            setModalBody(data.data.message);
          } else {
            setModalBody("Please try after sometime");
          }
          toggle();
        }
      })
      .catch((err) => {
        setModalTitle("Error");
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody("Please try after sometime.");
        }
        toggle();
      });
    setCurrentPage(selected);
  }, []);

  return (
    <div data-testid="CaseDetailsPage" className="casesDetails">
      <CommonNavbar
        title="Open Cases"
        backButton={path.DASHBOARD}
        search={false}
      />
      <div className="casesDetails-card">
        {Array.isArray(casesCards) &&
          casesCards.map((ele, index) => (
            <Case
              data-testid="caseinput"
              key={index}
              employeeRole={employeeRole}
              caseNumber={ele.caseNumber}
              cases={ele.cases}
              color={ele.color}
              bgColor={ele.bgColor}
              onClick={() => onCasesClick(ele.path)}
              boxShadow={ele.boxShadow}
            />
          ))}
      </div>
      <div
        className={
          employeeRole === "vendor" && caseidArr && caseidArr.length > 0
            ? "CasesDetailsfilter show-btns"
            : "CasesDetailsfilter"
        }
      >
        {open && (
          <FilterPopUp
            open={open}
            backgroundColor={"#8F949B80"}
            filterToggle={filterToggle}
            height={filterHeight ? filterHeight : "auto"}
          >
            <FilterActions
              caseType="open"
              onResetFilter={resetFilter}
              submitFilterform={(data) => submitFilterform(data)}
              filterform={filterform}
              datepickerClicked={(value) => datepickerClicked(value)}
            />
          </FilterPopUp>
        )}
        {getCases && (
          <ReactPaginate
            data-testid="reactPaginate"
            filterpopupShow={filterpopupShow}
            getCases={getCases}
            currentPage={currentPage}
            pageCount={pageCount}
            filterflag={filterflag}
            itemsPerPage={itemsPerPage}
            renderItems={filterflag ? renderItemssearch() : renderItems()}
            handlePageChange={(value) => handlePageChange(value)}
            totalrecords={totalrecords}
            caseType="open"
            downloadReport={downloadReport}
            showSelect={showSelect}
            handleShowSelect={handleShowSelect}
          />
        )}
        {employeeRole === "vendor" && caseidArr && caseidArr.length > 0 ? (
          <div className="AcceptButtons-ContainerParent">
            <div className="AcceptButtons-Container">
              <AcceptButtons
                background="#E42125"
                datatestid="save-button"
                onClick={() => doNotAcceptCase()}
              >
                Do Not Accept
              </AcceptButtons>
              <AcceptButtons
                background="#00AF00"
                datatestid="save-button-2"
                onClick={() => redirectAssignCase()}
              >
                Accept
              </AcceptButtons>
            </div>
          </div>
        ) : null}
      </div>
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  );
}
CasesDetails.propTypes = {
  userName: propTypes.string,
  employeeRole: propTypes.string,
  getCases: propTypes.array,
  setMultiCase: propTypes.func,
  caseidArr: propTypes.array,
};

const mapStateToProps = createStructuredSelector({
  employeeRole: getEmployeeRole(),
  casesDetails: makeSelectCasesDetails(),
  getCases: makeSelectCardCases(),
  caseidArr: makeMultiSelectCases(),
});

export function mapDispatchToProps(dispatch) {
  return {
    redirectPath: (path) => dispatch(push(path)),
    updateCase: (data) => dispatch(casesUpdate(data)),
    setMultiCase: (caseid) => dispatch(setMultiCaseId(caseid)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(CasesDetails);
