module.exports = {
  GENERATEOTP: "/validateUser",
  VERIFY_OTP: "/verifyOtp",
  EMPLOYEE_COUNT: "/employeeCount",
  EMPLOYEE_STATUS_UPDATE: "/statusUpdate",
  EMPLOYEE_DETAILS: "/getVendorDetails",
  ADD_EMPLOYEE: "/addInvestigators",
  CASES_COUNT: "/caseCount",
  CASE_DETAILS: "/getCasesDetails",
  CASE_BPRS_DETAILS: "/getCaseBPRSegmentDetails",
  CASE_CLAIM_DETAILS: "/getCaseClaimSegmentDetails",
  AISSIGN_INVESTIGATOR: "/assignInvestigator",
  GET_ASSIGNEDINVESTIGATOR: "/getAssignedInvestigatorsDetails",
  SUBMITCASE_REMARK: "/submitCaseRemark",
  CREATE_BLOCK_FORM: "/createDynamicBlockForm",
  UPLOAD_DOCUMENT: "/uploadDocuments",
  ADD_NEW_INVOICE: "/createInvoiceForm",
  SUBMIT_BLOCK_FORM_DETAILS: "/submitBlockFormDetails",
  DOWNLOAD_DOCUMENT: "/downloadDocuments",
  VIEW_DOCUMENT: "/viewDocuments",
  INVOICE_LIST: "/getInvoiceDocuments",
  GET_ADDED_INVOICE_IDS: "/getAddedInvoiceIds",
  GET_INVOICE_ID_DETAILS: "/invoiceIdDetails",
  DELETE_INVOICE: "/deleteinvoice",
  SUBMIT_NEW_INVOICE_FORM: "/submitInvoiceFormDetails",
  DELETE_NEW_INVOICE_LIST: "/deleteInvoiceDocuments",
  GET_GRADE_CASE: "/getGradeCase",
  SUBMIT_GRADE_CASE: "/submitGradeCase",
  GET_SIXMONTHCASECOUNT: "/caseTracker/caseRadialGraph",
  SUBMIT_INVESTIGATION_REPORT: "/submitInvestigationReport",
  FACT_FINDING_REPORT: "/generateFactFinding",
  GET_FILTER_FORM: "/getFilterForm",
  GET_SEARCH_CASES: "/getCasesDetails",
  GET_SUGGESTION_LIST: "/caseSearchSuggestions",
  GET_PROFILE_DETAIL: "/getProfileDetails",
  GET_STATE_MASTERLIST: "/stateMasterList",
  GET_CITY_MASTERLIST: "/getCitiesFromState",
  ADD_STATES_AND_CITIES: "/addStatesAndCities",
  SWITCH_PROFILE: "/profileSwitching",
  SERVICE_CITY_COUNT: "/cityCount",
  EDIT_SERVICE_STATE: "/editServicedState",
  EDIT_SERVICE_CITY: "/editServicedCity",
  GET_INVOICERADIALGRAPH: "/caseTracker/invoicesRadialGraph",
  GET_VENDOR_DOCS: "/getVendorDocuments",
  REJECTED_INVOICE_SUBMIT: "/submitRejectedInvoicesCases",
  REJECTED_INVOICE_SUBMIT_TO_VENDOR: "/rejectedInvoiceSubmitToVendor",
  GET_BRAGRAPH_DATA: "/caseTracker/casesBarGraph",
  CASES_TABLE_DETAIL: "/caseTracker/caseTableDetail",
  INVOICES_TABLE_DETAIL: "/caseTracker/invoiceTableDetail",
  GET_INVOICE_BAR_GRAPH: "/caseTracker/invoiceBarGraph",
  EMPLOYEE_DOWNLOAD_REPORT_DETAILS: "/employeeDownloadReportDetails",
  EMP_BULK_UPLOAD: "/bulkUploadEmployee",
  GET_AGEING_REPORT: "/dashboard/ageingReport",
  GET_EXCEL_EXPORT_DATA: "/getCasesDownloadDetails",
  GET_EMP_SUGGESTION_LIST: "/employeeSearchSuggestions",
  DO_NOT_ACCEPT_CASE: "/doNotAcceptCase",
  GET_EXTERNAL_DATA: "/externalChallan",
  GET_ALLINVOICE_DETAILS: "/getAllInvoiceDetails",
  LOGOUT: "/logout",
  GET_REGULATORY_DETAILS: "/vendorRegulatoryDetails",
  STORE_REGULATORY_DETAILS: "storeVendorRegulatoryDetails",
};
