import React, { createContext, useContext } from "react";
import { DataDispatchContext } from "./DataContext";
import urlConstants from "../constants/urlConstants";
import { encryptData } from "../utils/crypto";

const ApiDispatchContext = createContext(undefined);

function APIProvider({ children }) {
  const apiPost = useContext(DataDispatchContext).apiPost;
  const apiGet = useContext(DataDispatchContext).apiGet;

  const setJWT = (jwt) => {
    return new Promise((resolve, reject) => {
      // Simulate an asynchronous operation, e.g., fetching data from an API
      setTimeout(() => {
        const expires = new Date(Date.now() + 60 * 60 * 10000).toUTCString();
        document.cookie = `jwtToken=${jwt}; expires=${expires}; path=/; Secure; HttpOnly;`;
        const data = "This is the data fetched from an API.";
        // Resolve the promise with the fetched data
        resolve(data);
        // If there's an error, you can reject the promise
        // reject('Error: Unable to fetch data');
      }, 2000); // Simulating a 2-second delay
    });
  };

  const getJWT = () => {
    const jwt = retrieveJWT(document.cookie);
    return jwt;
  };

  function retrieveJWT(jwtTokenString) {
    if (!jwtTokenString) {
    }

    let jwtArray = jwtTokenString.split(";");
    let jwtCookie = jwtArray.find((cookie) => cookie.includes("jwtToken="));

    if (!jwtCookie) {
      return null;
    }
    return jwtCookie.split("=")[1];
  }

  const deleteJWT = () => {
    document.cookie = "jwtToken=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
  };

  const generateOtp = (data) => {
    return apiPost(urlConstants.GENERATEOTP, data);
  };

  const verifyOtp = (data) => {
    return apiPost(urlConstants.VERIFY_OTP, data, {
      withCredentials: true,
    });
  };

  const switchProfile = () => {
    return apiGet(urlConstants.SWITCH_PROFILE, {
      withCredentials: true,
    });
  };

  const employeeStatusUpdate = (data) => {
    return apiPost(urlConstants.EMPLOYEE_STATUS_UPDATE, data, {
      withCredentials: true,
    });
  };

  const employeeDetails = (data) => {
    return apiPost(urlConstants.EMPLOYEE_DETAILS, encryptData(data), {
      headers: { "Content-Type": "text/plain" },
      withCredentials: true,
    });
  };

  const addEditInvesigator = (data) => {
    return apiPost(urlConstants.ADD_EMPLOYEE, data, {
      withCredentials: true,
    });
  };

  const employeeCount = () => {
    return apiGet(urlConstants.EMPLOYEE_COUNT, {
      withCredentials: true,
    });
  };

  const casesCount = () => {
    return apiGet(urlConstants.CASES_COUNT, {
      withCredentials: true,
    });
  };

  const getCasesDetails = (data) => {
    return apiPost(urlConstants.CASE_DETAILS, data, {
      withCredentials: true,
    });
  };

  const invoiceIdDetails = (data) => {
    return apiGet(urlConstants.GET_INVOICE_ID_DETAILS, data, {
      withCredentials: true,
    });
  };

  const getAddedInvoiceIds = (data) => {
    return apiPost(urlConstants.GET_ADDED_INVOICE_IDS, data, {
      withCredentials: true,
    });
  };

  const deleteinvoice = (data) => {
    return apiPost(urlConstants.DELETE_INVOICE, data, {
      withCredentials: true,
    });
  };

  const getInvoiceList = (data) => {
    return apiPost(urlConstants.INVOICE_LIST, data, {
      withCredentials: true,
    });
  };

  const deleteNewInvoiceList = (data) => {
    return apiPost(urlConstants.DELETE_NEW_INVOICE_LIST, data, {
      withCredentials: true,
    });
  };

  const getCaseBPRSegmentDetails = (data) => {
    return apiPost(urlConstants.CASE_BPRS_DETAILS, data, {
      withCredentials: true,
    });
  };

  const getCaseClaimSegmentDetails = (data) => {
    return apiPost(urlConstants.CASE_CLAIM_DETAILS, data, {
      withCredentials: true,
    });
  };

  const assignInvestigator = (data) => {
    return apiPost(urlConstants.AISSIGN_INVESTIGATOR, data, {
      withCredentials: true,
    });
  };

  const getAssignedInvestigatorsDetails = (data) => {
    return apiPost(urlConstants.GET_ASSIGNEDINVESTIGATOR, data, {
      withCredentials: true,
    });
  };

  const submitCaseRemark = (data) => {
    return apiPost(urlConstants.SUBMITCASE_REMARK, data, {
      withCredentials: true,
    });
  };

  const createDynamicBlockForm = (data) => {
    return apiPost(urlConstants.CREATE_BLOCK_FORM, data, {
      withCredentials: true,
    });
  };

  const addNewInvoiceForm = (data) => {
    return apiPost(urlConstants.ADD_NEW_INVOICE, data, {
      withCredentials: true,
    });
  };

  const uploadDocuments = (data) => {
    return apiPost(urlConstants.UPLOAD_DOCUMENT, data, {
      withCredentials: true,
    });
  };

  const uploadBulkUpload = (data) => {
    return apiPost(urlConstants.EMP_BULK_UPLOAD, data, {
      withCredentials: true,
    });
  };

  const submitBlockFormDetails = (data) => {
    return apiPost(urlConstants.SUBMIT_BLOCK_FORM_DETAILS, data, {
      withCredentials: true,
    });
  };

  const submitNewInvoiceForm = (data) => {
    return apiPost(urlConstants.SUBMIT_NEW_INVOICE_FORM, data, {
      withCredentials: true,
    });
  };

  const downloadDocuments = (data) => {
    return apiPost(urlConstants.DOWNLOAD_DOCUMENT, data, {
      withCredentials: true,
    });
  };
  const viewDocuments = (data) => {
    return apiPost(urlConstants.VIEW_DOCUMENT, data, {
      withCredentials: true,
    });
  };
  const getGradeCase = (data) => {
    return apiPost(urlConstants.GET_GRADE_CASE, data, {
      withCredentials: true,
    });
  };
  const submitGradeCase = (data) => {
    return apiPost(urlConstants.SUBMIT_GRADE_CASE, data, {
      withCredentials: true,
    });
  };
  const factFindingReport = (data) => {
    return apiPost(urlConstants.FACT_FINDING_REPORT, data, {
      withCredentials: true,
    });
  };

  const submitInvestigationReport = (data) => {
    return apiPost(
      urlConstants.SUBMIT_INVESTIGATION_REPORT + `?caseId=${data.caseid}`,
      data.file,
      {
        withCredentials: true,
      }
    );
  };

  const getFilterForm = (data) => {
    return apiPost(urlConstants.GET_FILTER_FORM, data, {
      withCredentials: true,
    });
  };

  const getSearchCases = (data) => {
    return apiPost(urlConstants.GET_SEARCH_CASES, data, {
      withCredentials: true,
    });
  };

  const getSuggestionList = (data) => {
    return apiPost(urlConstants.GET_SUGGESTION_LIST, data, {
      withCredentials: true,
    });
  };

  const getProfileDetail = () => {
    return apiGet(urlConstants.GET_PROFILE_DETAIL, {
      withCredentials: true,
    });
  };

  const serviceCityCount = () => {
    return apiGet(urlConstants.SERVICE_CITY_COUNT, {
      withCredentials: true,
    });
  };

  const editStateList = (data) => {
    return apiPost(urlConstants.EDIT_SERVICE_STATE, data, {
      withCredentials: true,
    });
  };

  const getVendorDocs = () => {
    return apiGet(urlConstants.GET_VENDOR_DOCS, {
      withCredentials: true,
    });
  };

  const changeCityStatus = (data) => {
    return apiPost(urlConstants.EDIT_SERVICE_CITY, data, {
      withCredentials: true,
    });
  };

  const invoicetadialGraph = () => {
    return apiGet(urlConstants.GET_INVOICERADIALGRAPH, {
      withCredentials: true,
    });
  };

  const sixMonthCaseCount = () => {
    return apiGet(urlConstants.GET_SIXMONTHCASECOUNT, {
      withCredentials: true,
    });
  };

  const getStateMaster = () => {
    return apiGet(urlConstants.GET_STATE_MASTERLIST, {
      withCredentials: true,
    });
  };

  const getCityMaster = (data) => {
    return apiPost(urlConstants.GET_CITY_MASTERLIST, data, {
      withCredentials: true,
    });
  };

  const addStatesAndCities = (data) => {
    return apiPost(urlConstants.ADD_STATES_AND_CITIES, data, {
      withCredentials: true,
    });
  };

  const rejectedInvoiceSubmit = (data) => {
    return apiPost(urlConstants.REJECTED_INVOICE_SUBMIT, data, {
      withCredentials: true,
    });
  };

  const rejectedInvoiceSubmitToVendor = (data) => {
    return apiPost(urlConstants.REJECTED_INVOICE_SUBMIT_TO_VENDOR, data, {
      withCredentials: true,
    });
  };

  const getCasetrackerBarData = () => {
    return apiGet(urlConstants.GET_BRAGRAPH_DATA, {
      withCredentials: true,
    });
  };

  const getReportExcel = (data) => {
    return apiPost(urlConstants.GET_EXCEL_EXPORT_DATA, data, {
      withCredentials: true,
    });
  };

  const getInvoicetrackerBarData = () => {
    return apiGet(urlConstants.GET_INVOICE_BAR_GRAPH, {
      withCredentials: true,
    });
  };

  const casesTrackerTableData = (data) => {
    return apiPost(urlConstants.CASES_TABLE_DETAIL, data, {
      withCredentials: true,
    });
  };

  const employeeDownloadReportDetails = (data) => {
    return apiPost(urlConstants.EMPLOYEE_DOWNLOAD_REPORT_DETAILS, data, {
      withCredentials: true,
    });
  };

  const invoicesTrackerTableData = (data) => {
    return apiPost(urlConstants.INVOICES_TABLE_DETAIL, data, {
      withCredentials: true,
    });
  };

  const getAgeingReportData = (data) => {
    return apiPost(urlConstants.GET_AGEING_REPORT, data, {
      withCredentials: true,
    });
  };

  const doNotAcceptCase = (data) => {
    return apiPost(urlConstants.DO_NOT_ACCEPT_CASE, data, {
      withCredentials: true,
    });
  };

  const getEmpSuggestionList = (data) => {
    return apiPost(urlConstants.GET_EMP_SUGGESTION_LIST, data, {
      withCredentials: true,
    });
  };

  const getExternalData = (data) => {
    return apiPost(urlConstants.GET_EXTERNAL_DATA, data, {
      withCredentials: true,
    });
  };

  const getAllInvoiceDetails = (data) => {
    return apiPost(urlConstants.GET_ALLINVOICE_DETAILS, data, {
      withCredentials: true,
    });
  };
  const getRegulatoryDetails = (query) => {
    const {caseid,gradeStatusId, gradeStatus} = query;
    return apiGet(
      urlConstants.GET_REGULATORY_DETAILS + `?caseId=${caseid}&gradeStatusId=${gradeStatusId}&gradeStatus=${encodeURIComponent(gradeStatus)}`,
      {
        withCredentials: true,
      }
    );
  };

  const storeRegulatoryDetails = (data) => {
    return apiPost(urlConstants.STORE_REGULATORY_DETAILS, data, {
      withCredentials: true,
    });
  };

  const allSetFun = {
    generateOtp,
    uploadDocuments,
    verifyOtp,
    setJWT,
    getJWT,
    deleteJWT,
    employeeCount,
    employeeStatusUpdate,
    employeeDetails,
    employeeDownloadReportDetails,
    addEditInvesigator,
    casesCount,
    getCasesDetails,
    getCaseBPRSegmentDetails,
    assignInvestigator,
    getCaseClaimSegmentDetails,
    getAssignedInvestigatorsDetails,
    submitCaseRemark,
    createDynamicBlockForm,
    addNewInvoiceForm,
    submitBlockFormDetails,
    downloadDocuments,
    getInvoiceList,
    invoiceIdDetails,
    getAddedInvoiceIds,
    deleteinvoice,
    submitNewInvoiceForm,
    deleteNewInvoiceList,
    getGradeCase,
    submitGradeCase,
    submitInvestigationReport,
    factFindingReport,
    viewDocuments,
    getFilterForm,
    getSearchCases,
    getSuggestionList,
    getStateMaster,
    getProfileDetail,
    getCityMaster,
    addStatesAndCities,
    switchProfile,
    serviceCityCount,
    editStateList,
    changeCityStatus,
    sixMonthCaseCount,
    invoicetadialGraph,
    getVendorDocs,
    rejectedInvoiceSubmit,
    rejectedInvoiceSubmitToVendor,
    getCasetrackerBarData,
    casesTrackerTableData,
    invoicesTrackerTableData,
    getInvoicetrackerBarData,
    uploadBulkUpload,
    getAgeingReportData,
    getReportExcel,
    doNotAcceptCase,
    getEmpSuggestionList,
    getExternalData,
    getAllInvoiceDetails,
    getRegulatoryDetails,
    storeRegulatoryDetails,
  };
  return (
    <ApiDispatchContext.Provider value={allSetFun}>
      {children}
    </ApiDispatchContext.Provider>
  );
}

export { APIProvider, ApiDispatchContext };
